import { API_URI } from '.'
import ArrayPayload from '../models/ArrayPayload'
import GlobalSetting from '../models/GlobalSetting'
import { getHeaders } from './auth'

export async function getGlobalSettings(): Promise<
	ArrayPayload<GlobalSetting>
> {
	const response = await fetch(`${API_URI}/globalsettings`, {
		method: 'GET',
		headers: getHeaders(),
	})

	return await response.json()
}
