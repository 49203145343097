import { createContext, useContext } from 'react'
import { Context } from './Context'

const context = createContext<Context>({
	getSettingValue: () => '',
})

export const ContextProvider = context.Provider

export const useAppContext = () => useContext(context)
