import React, { useMemo, useState } from 'react'
import { useAsync } from 'react-use'
import { ThemeProvider } from 'styled-components'
import { getGlobalSettings } from './src/apiClients/global-settings'
import { createGetSettingValue } from './src/utils/models'
import { ContextProvider } from './src/context'
import { defaultTheme } from './src/styles/defaultTheme'

function getHeaders() {
	const headers = new Headers()
	headers.append('Content-Type', 'application/json')
	return headers
}

async function getThemeItems() {
	const response = await fetch(`/api/theme-items`, {
		method: 'GET',
		headers: getHeaders(),
	})

	return await response.json()
}

const Theming = ({ element }) => {
	const [theme, setTheme] = useState(defaultTheme)

	useAsync(async () => {
		const response = await getThemeItems()
		const newTheme = { ...defaultTheme }
		response.data
			.flatMap(d => d.attributes)
			.forEach(i => {
				newTheme[i.key] = i.value
			})
		setTheme(newTheme)
	}, [])

	return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

const Provider = ({ children }) => {
	const [settings, setSettings] = useState()

	useAsync(async () => {
		const settings = await getGlobalSettings()
		if (!settings) return
		setSettings(settings)
	}, [])

	const getSettingValue = useMemo(
		() => createGetSettingValue(settings),
		[settings]
	)

	return (
		<ContextProvider value={{ getSettingValue }}>{children}</ContextProvider>
	)
}

export const wrapRootElement = ({ element }) => (
	<Provider>
		<Theming element={element} />
	</Provider>
)
